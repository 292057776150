import { startAppListening } from '../../app/listener';
import { getContactInfoForPage } from './slice';
import beacon from '@vfde-sails/beacon';
import {
    BEACON_FLYOUT_EVENT,
    BEACON_EVENT_SET_CONTACT_INFO,
} from './constant';

startAppListening({
    actionCreator: getContactInfoForPage.fulfilled,
    effect: async (action, { delay, cancelActiveListeners }) => {
        cancelActiveListeners();

        // Publish contact information to global navigation contact section
        await delay(100);

        beacon.publish(BEACON_FLYOUT_EVENT,
            {
                type: BEACON_EVENT_SET_CONTACT_INFO,
                payload: {
                    contactInfo: {
                        ...action.payload,
                    },
                },
            });
    },
});
