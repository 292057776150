import {
    Overlay,
    createOverlay,
    OVERLAY_ALIGNMENT_LEFT,
    OVERLAY_ANIMATION_SLIDE,
} from '@vfde-brix/ws10/overlay';
import beacon from '@vfde-sails/beacon';
import {
    CONTAINER_FLYOUT_OVERLAY,
    CONTAINER_HOTLINE_BUTTON,
    FLYOUT_CLASSNAME,
    FLYOUT_TRACKING_TOOL_CHAT,
    FLYOUT_TRACKING_TOOL_HOTLINE,
    BEACON_FLYOUT_EVENT,
    BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS,
    HOTLINE_HEADLINE_ID,
    HOTLINE_OPENING_HOURS_ID,
    TOBI_WEBCHAT_BUTTON_CONTAINER_ID,
    TOBI_WEBCHAT_PANEL_ID,
} from '../container/Flyout/constant';
import {
    closeOverlay,
    fetchOverlay,
    injectOverlayContent,
    openOverlay,
} from '@vfde-sails/overlay';
import { useDispatch } from '../app/store';
import { startAppListening } from '../app/listener';
import mountFlyout from './Flyout';
import {
    createComponentById,
    createComponents,
    NO_PATTERN_BUSINESS_LOGIC,
} from '@vfde-brix/ws10/core';
import {
    ACCORDION_BASE_CLASSNAME,
    createAccordion,
} from '@vfde-brix/ws10/accordion';
import {
    createTabs,
    TABS_BASE_CLASSNAME,
} from '@vfde-brix/ws10/tabs';
import {
    selectContactInfo,
    startTobi,
} from '../container/Flyout/slice';
import {
    createButtonLink,
    WS10_BUTTON_LINK_BASE_CLASSNAME,
} from '@vfde-brix/ws10/button-link';
import { trackFlyoutButtonEngage } from '../container/Flyout/helper/tracking';
import { createHeadline } from '@vfde-brix/ws10/headline';
import { createJustText } from '@vfde-brix/ws10/just-text';
import { Flyout } from '@vfde-brix/ws10/flyout';
import { createTelUrl } from '@vfde-sails/utils';

/**
 * mount overlay
 */
export const mountOverlay = (): Overlay | null => {
    const dispatch = useDispatch();

    let flyout: Flyout;

    let overlayContainer = document.getElementById(CONTAINER_FLYOUT_OVERLAY);

    if (!overlayContainer) {
        overlayContainer = document.createElement('div');
        overlayContainer.id = CONTAINER_FLYOUT_OVERLAY;
        overlayContainer.className = FLYOUT_CLASSNAME;
        document.body.append(overlayContainer);
    }

    const overlay = overlayContainer && createOverlay(overlayContainer, {
        optContentAlign: OVERLAY_ALIGNMENT_LEFT,
        optAnimation: OVERLAY_ANIMATION_SLIDE,
        optPaddingLayout: true,
        optAutoFocus: true,
        business: {
            onOpen: () => {
                dispatch(openOverlay());
            },
            onInject: () => {
                dispatch(injectOverlayContent());
            },
            onClose: () => {
                dispatch(closeOverlay());
                beacon.publish(BEACON_FLYOUT_EVENT, {
                    type: BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS,
                    payload: {
                        activePage: null,
                    },
                });
            },
        },
    });

    startAppListening({
        actionCreator: fetchOverlay.fulfilled,
        effect: action => {
            if (flyout) {
                overlay.open();

                return;
            }

            if (action.payload.content) {
                overlay.injectContent(action.payload.content);
            }
        },
    });

    startAppListening({
        actionCreator: injectOverlayContent.fulfilled,
        effect: (_, { getState }) => {
            // Mount flyout
            flyout = mountFlyout(overlay.getOverlayContentContainer());

            const contactInfo = selectContactInfo(getState());

            // Mount accordions inside flyout
            createComponents(
                `.${ACCORDION_BASE_CLASSNAME}`,
                createAccordion,
                NO_PATTERN_BUSINESS_LOGIC,
                flyout.getContainerElement(),
            );

            // Mount tabs inside flyout
            createComponents(
                `.${TABS_BASE_CLASSNAME}`,
                createTabs,
                NO_PATTERN_BUSINESS_LOGIC,
                flyout.getContainerElement(),
            );

            // Mount editorial button links, add tracking to click handler
            const buttonLinks = (createComponents(`.${WS10_BUTTON_LINK_BASE_CLASSNAME}`, createButtonLink, {
                onClick: (_event, _href, trackingName) => {
                    const tool = trackingName;
                    let hotline: string | undefined;

                    if (tool === FLYOUT_TRACKING_TOOL_HOTLINE) {
                        hotline = contactInfo?.phoneNumber;
                    }

                    tool && trackFlyoutButtonEngage(tool, hotline);
                },
            }, flyout.getContainerElement()));

            contactInfo?.phoneNumber && buttonLinks
                .find(buttonLink => buttonLink.getContainerElement().id === CONTAINER_HOTLINE_BUTTON)
                ?.update({
                    linkHref: createTelUrl(contactInfo.phoneNumber),
                });

            const openWebchatButtonLink = buttonLinks
                .find(buttonLink => buttonLink.getContainerElement().id === TOBI_WEBCHAT_BUTTON_CONTAINER_ID);
            openWebchatButtonLink?.update({
                stdAriaControls: TOBI_WEBCHAT_PANEL_ID,
                business: {
                    onClick: () => {
                        trackFlyoutButtonEngage(FLYOUT_TRACKING_TOOL_CHAT);
                        dispatch(startTobi());
                        openWebchatButtonLink.toggleAriaExpanded(true);
                    },
                },
            });

            createComponentById(createHeadline, HOTLINE_HEADLINE_ID, NO_PATTERN_BUSINESS_LOGIC)
                ?.update({
                    stdContent: contactInfo?.phoneNumber,
                });

            createComponentById(createJustText, HOTLINE_OPENING_HOURS_ID, NO_PATTERN_BUSINESS_LOGIC)
                ?.update({
                    content: contactInfo?.openingHours.standard,
                });

            overlay.open();
        },
    });

    return overlay;
};
