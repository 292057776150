import { IFlyoutGlobalPageOptions } from '../interface';

/**
 * Reads the hidden notch value
 * window['globalPageOptions'].flyout.hiddenNotch
 * Indicating if the flyout should be visible or not
 */
export const getHiddenNotchFromWindow = (): IFlyoutGlobalPageOptions['hiddenNotch'] =>
    window.globalPageOptions?.flyout?.hiddenNotch || false;
/**
 * Reads the url
 * window['globalPageOptions'].flyout.url
 * Location of the flyout content
 */
export const getContentUrlFromWindow = (): IFlyoutGlobalPageOptions['url'] | undefined =>
    window.globalPageOptions?.flyout?.url;
